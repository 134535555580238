import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import emailjs from "@emailjs/browser";
// import './SamplePackages/PackageDetails.css';
import "./GetCallBack.css";

const GetCallBack = () => {
    const [showForm, setShowForm] = useState(false);
  const form = useRef();
  const navigate = useNavigate();

  const handleFormSubmit = (e) => {
    setShowForm(false);
    // e.preventDefault();
  };

  const closeFormSubmit = (e) => {
    navigate("/")
  }

  const sendEmail = (e) => {
    e.preventDefault();
   
    emailjs
      .sendForm("service_9mh32bh", "template_iucwdt1", form.current, {
        publicKey: "9BO9CFVCt4ge1O4gY",
      })
      .then(
        (result) => {
          console.log("SUCCESS!", result.text);
          console.log("Message Sent");
          e.target.reset();
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  
  return (
    <div>
        <div className="enquiry-section">
        <h3>Allow Us to Call You Back!</h3>
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" placeholder="Name" name="user_name" required />
          <input type="text" placeholder="City" name="user_city" required />
          <input type="email" placeholder="Email address" name="user_email" required />
          <input type="number" placeholder="Phone" name="user_number" maxLength={10} required />
            <input
              type="text"
              list="options"
              id="destination"
              name="user_destination"
              placeholder="Choose Destinations"
              required
            />
            <datalist id="options">
              <option value="Pinnawala Elephant Orphanage" />
              <option value="Galle Port" />
              <option value="Iconic lotus Tower" />
              <option value="Sigiriya" />
              <option value="Nine Arch Bridge" />
              <option value="Others Destinations" />
            </datalist>
          <input type="number" placeholder="Travel Count" name="user_people" required />
          <div className='buttons'>
            <button className='submit' type="submit" onClick={handleFormSubmit}>Send Enquiry</button>
            <button className='close' type="submit" onClick={closeFormSubmit}>Close</button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default GetCallBack;
         
// <div className='buttons'>
          //   <button className='submit' type="submit" onClick={handleFormSubmit}>Send Enquiry</button>
          //   <button className='close' type="submit" onClick={closeFormSubmit}>Close</button>
          // </div>