import React from "react";
import { useParams } from "react-router-dom";
import "./blog.css";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";

const FullBlogPost = ({ posts }) => {
  const { id } = useParams();
  const post = posts.find((p) => p.id === parseInt(id));

  if (!post) {
    return <h2>Blog Post not found</h2>;
  }

  return (
    <>
    <Navbar />
    <div className="full-blog-post">
      <p>{post.summary}</p>
      <p>{post.conclusions}</p>
      <p>
        <em>
          By {post.author} on {post.date}
        </em>
      </p>
      
    </div>
    <Footer />
    </>
  );
};

export default FullBlogPost;
