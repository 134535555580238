import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./testimonial.css"; // Import your custom CSS for styling

import groupTrip from "../../assets/images/customeres-review/group-trip.jpg";
import customer2 from "../../assets/images/customeres-review/srilanka-review1.jpg";
import customer3 from "../../assets/images/customeres-review/srilanka-review2.jpg";
import customer4 from "../../assets/images/customeres-review/srilanka-review3.jpg";
import customer5 from "../../assets/images/customeres-review/srilanka-review4.jpg";

const Carousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 700,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true, // Enable auto sliding
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024, // For tablets and below
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600, // For mobile devices
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="carousel-container">
      <h1>Our Happy Customers's</h1>
      <Slider {...settings}>
        <div className="carousel-item">
          <img src={groupTrip} alt="Slide 1" />
          <p>Perfect for groups, Sri Lanka offers thrilling safaris, scenic train rides, and vibrant nightlife. Explore Sigiriya, hike Ella's trails, and relax on Mirissa's beaches for an unforgettable adventure​</p>
          {/* <p>With a wealth of experience in the travel industry, GOL Travels is a pioneer to serve you with expertise and dedication.With a wealth of experience in the travel industry, GOL Travels is a pioneer to serve you with expertise and dedication.</p> */}
          <h4>Namitha and family</h4>
        </div>
        <div className="carousel-item">
          <img src={customer2} alt="Slide 2" />
          <p>Romantic escapes include serene beaches in Mirissa, luxury stays in Bentota, private safaris in Yala, or tea estate tours in Nuwara Eliya for a magical honeymoon experience​</p>
          <h4>Vinay Joshi</h4>
        </div>
        <div className="carousel-item">
          <img src={customer3} alt="Slide 3" />
          <p><span style={{fontWeight:600}}>Galle and Bentota Charm:</span> Couples rave about romantic strolls along the Galle Fort and candlelight dinners by the beach in Bentota. Luxurious stays and serene sunsets add magic to the experience</p>
          <h4>Neeraj Singh</h4>
        </div>
        <div className="carousel-item">
          <img src={customer4} alt="Slide 4" />
          <p>Solo trips to Sri Lanka are rewarding for women, with its beauty and hospitality. Stay cautious, dress modestly, avoid isolated areas at night, use trusted transport, and connect with fellow travelers​</p>
          <h4>Vaishnavi</h4>
        </div>
        <div className="carousel-item">
          <img src={customer5} alt="Slide 5" />
          <p>Explore vibrant markets, hike lush trails in Ella, surf at Arugam Bay, or visit ancient cities like Sigiriya. Enjoy adventure, history, and peace amidst Sri Lanka's scenic landscapes​
          </p>
          <h4>Ramodhar Rao</h4>
        </div>
      </Slider>
    </div>
  );
};

export default Carousel;


// import React from "react";
// import Slider from "react-slick";
// import ava01 from "../../assets/images/gallery-img01.jpg";
// import ava02 from "../../assets/images/gallery-img02.jpg";
// import ava03 from "../../assets/images/gallery-img03.jpg";
// import ava04 from "../../assets/images/gallery-img04.jpg";
// import ava05 from "../../assets/images/gallery-img05.jpg";

// import "./testimonial.css";

// const Testimonials = () => {
//   const settings = {
//     dots: true,
//     infinite: true,
//     autoplay: true,
//     speed: 1000,
//     swipeToSlide: true,
//     autoplaySpeed: 2000,
//     slidesToShow: 3,

//     responsive: [
//       {
//         breakpoint: 992,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 1,
//           infinite: true,
//           dots: true,
//         },
//       },
//       {
//         breakpoint: 576,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//         },
//       },
//     ],
//   };

//   return (
//     <Slider {...settings}>
//       <div className="testimonial py-4 px-3">
//         <p>
//           GOL Travels turned my vacation into a personal love story with
//           destinations handpicked just for me. It felt like they knew my travel
//           dreams intimately and brought them to life. Thanks to them, my
//           suitcase is now filled with memories, not just souvenirs!
//         </p>
//         <div className="d-flex align-items-center gap-4 mt-3">
//           <img src={ava01} className="w-25 h-25 rounded-2" alt="" />
//           <div>
//             <h6 className="mb-0 mt-3">Prajwal R</h6>
//             <p>Customer</p>
//           </div>
//         </div>
//       </div>
//       <div className="testimonial py-4 px-3">
//         <p>
//           Our family adventure with GOL was like a magic carpet ride of joy.
//           From expert advice on family-friendly spots to making us feel like
//           VIPs everywhere we went, it was a memory-making marathon.
//         </p>
//         <div className="d-flex align-items-center gap-4 mt-3">
//           <img src={ava02} className="w-25 h-25 rounded-2" alt="" />
//           <div>
//             <h6 className="mb-0 mt-3">Paritosh upadhya</h6>
//             <p>Customer</p>
//           </div>
//         </div>
//       </div>
//       <div className="testimonial py-4 px-3">
//         <p>
//           Every detail was like a note literally creating a unique travel
//           composition just for me. If you want a travel experience as unique as
//           you are, they are your conductor.
//         </p>
//         <div className="d-flex align-items-center gap-4 mt-3">
//           <img src={ava03} className="w-25 h-25 rounded-2" alt="" />
//           <div>
//             <h6 className="mb-0 mt-3">Govindaraj PG </h6>
//             <p>Customer</p>
//           </div>
//         </div>
//       </div>
//       <div className="testimonial py-4 px-3">
//         <p>
//           My honeymoon wasn't just a trip, it was a love story written by GOL
//           Travels. From romantic hideaways to personalized surprises, every
//           moment felt like a scene from a fairy tale.
//         </p>
//         <div className="d-flex align-items-center gap-4 mt-3">
//           <img src={ava04} className="w-25 h-25 rounded-2" alt="" />
//           <div>
//             <h6 className="mb-0 mt-3">Rahul Jain</h6>
//             <p>Customer</p>
//           </div>
//         </div>
//       </div>
//       <div className="testimonial py-4 px-3">
//         <p>
//           Traveling solo with GOL was like having a local friend in every
//           destination. Safety was a priority, but so was the freedom to explore.
//           They gave me not just a trip but a solo adventure that felt like a
//           journey with a trusted companion. They've won a solo traveler's heart
//         </p>
//         <div className="d-flex align-items-center gap-4 mt-3">
//           <img src={ava05} className="w-25 h-25 rounded-2" alt="" />
//           <div>
//             <h6 className="mb-0 mt-3">Deepika Balachandra</h6>
//             <p>Customer</p>
//           </div>
//         </div>
//       </div>
//     </Slider>
//   );
// };

// export default Testimonials;
