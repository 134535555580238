// src/components/BlogPostCard.js
import React from "react";
import { Link } from "react-router-dom";
import "./blog.css";

const BlogPostCard = ({ id, image, title, summary, author, date }) => {
  return (
    <div className="card123 mb-2">
      <div className="card-body">
        <Link to={`/blogs/${id}`}>
          <img src={image} alt="imag" style={{ width: "100%" }} />
        </Link>

        <div className="date-author">
          <p className="card-text">
            <small className="text-muted">{author}</small>
          </p>
          <p>
            <small className="text-muted">{date}</small>
          </p>
        </div>

        <Link to={`/blogs/${id}`} style={{ color: "black", fontSize: "2px" }}>
          <h5
            style={{
              color: "black",
              fontSize: "18px",
              gap: "none",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            {title}
          </h5>
        </Link>
        {/* <h5 className="card-title">{title}</h5> */}

        <Link to={`/blogs/${id}`} className="btn btn-primary">
          Read More
        </Link>
      </div>
    </div>
  );
};

export default BlogPostCard;
