// import galleryImg01 from "../../assets/images/gallery-01.jpg";
// import galleryImg01 from "../../assets/images/gallery-img01.jpg";
// import galleryImg02 from "../../assets/images/gallery-img05.jpg";
// import galleryImg03 from "../../assets/images/gallery-img02.jpg";
// import galleryImg04 from "../../assets/images/gallery-img03.jpg";
// import galleryImg05 from "../../assets/images/gallery-img06.jpg";
// import galleryImg06 from "../../assets/images/gallery-img07.jpg";
// import galleryImg07 from "../../assets/images/gallery-img08.jpg";

import galleryImg01 from "../../assets/galleryImage/Galle1.png";
import galleryImg02 from "../../assets/galleryImage/LotusTower2.png";
import galleryImg03 from "../../assets/galleryImage/NineArchBridge.png";
import galleryImg04 from "../../assets/galleryImage/Rawana-Falls.png";
import galleryImg05 from "../../assets/galleryImage/Temple2.png";
import galleryImg06 from "../../assets/galleryImage/Traditional.png";
import galleryImg07 from "../../assets/galleryImage/Traditional2.png";
import galleryImg08 from "../../assets/galleryImage/Udawalawe-Park.png";

// import galleryImg08 from "../../assets/images/gallery-img04.jpg";

const gallryImages = [
  galleryImg01,
  galleryImg07,
  galleryImg02,
  galleryImg06,
  galleryImg03,
  galleryImg04,
  galleryImg05,
  galleryImg08,
];

export default gallryImages;
