export const MenuItems = [
   
    {
        title: "Packages",
        url: "/Packages",
        Cname: "nav-links",
        // icon: "fa-solid fa-briefcase"
    },
    {
        title: "What We Do",
        url: "/what-we-do",
        Cname: "nav-links",
        // icon: "fa-solid fa-briefcase"
    },
    {
        title: "About",
        url: "/about",
        Cname: "nav-links",
        // icon: "fa-solid fa-circle-info"
    },
    {
        title: "Contact",
        url: "/contact",
        Cname: "nav-links",
        // icon: "fa-solid fa-address-book"
    },
    // {
    //     title: "Blogs",
    //     url: "/blogs",
    //     Cname: "nav-links-mobile",
    // },
    {
        title: "Blogs",
        url: "/blogs",
        Cname: "nav-links",
        // icon: "fa-solid fa-house-user"
    },
]