// // import React from "react";
// // import { useParams, Link } from "react-router-dom";
// // import "./PackageDetails.css";
// // import Navbar from "../Navbar";

// // const packageDetails = {
// //   1: {
// //     name: "Hills & Sands",
// //     days: ["Day 1: Hills & Sands -- BIA - Dambulla", "Day 2: Dambulla - Kandy", "Day 3: Kandy - Ella", "Day 4: Ella - Mirissa", "Day 5: Mirissa - Bentota"],
// //     activities: ["Sigiriya Rock", "Temple of Tooth", "Ravana Falls", "Nine Arch Bridge", "Whale Watching"],
// //   },
// //   2: {
// //     name: "Historical & Chill",
// //     days: ["Day 1: Historical & Chill -- BIA - Dambulla", "Day 2: Dambulla - Kandy", "Day 3: Kandy - Nuwara Eliya", "Day 4: Nuwara Eliya - Bentota"],
// //     activities: ["Pinnawala Elephant Orphanage", "Traditional Kandy Dance", "Ambuluwawa Tower", "Bentota Turtle Hatchery"],
// //   },
// //   3: {
// //     name: "Chill & Wild",
// //     days: ["Day 1: Chill & Wild -- BIA - Kandy", "Day 2: Kandy - Nuwara Eliya", "Day 3: Nuwara Eliya - Ella", "Day 4: Ella - Udawalawa"],
// //     activities: ["Tea Factory Visit", "Ashoka Vatika Temple", "Udawalawa Safari", "Ravana Falls", "Nine Arch Bridge"],
// //   },
// // };

// // const PackageDetails = () => {
// //   const { id } = useParams();
// //   const details = packageDetails[id];

// //   return (
// //     <>
// //     <Navbar />
// //     <div className="sample-package-details">
// //       {/* <Link to="/" style={{ textDecoration: "none", color: "blue" }}>
// //         Back to Home
// //       </Link> */}
// //       <h1>{details.name}</h1>
// //       <h3>Itinerary</h3>
// //       <ul>
// //         {details.days.map((day, index) => (
// //           <li key={index}>{day}</li>
// //         ))}
// //       </ul>
// //       <h3>Activities</h3>
// //       <ul>
// //         {details.activities.map((activity, index) => (
// //           <li key={index}>{activity}</li>
// //         ))}
// //       </ul>
// //     </div>
// //     </>
// //   );
// // };

// // export default PackageDetails;

//  //above code working fine and below code is working on it

// import React from "react";
// import { useParams, Link } from "react-router-dom";
// import "./PackageDetails.css";
// import Navbar from "../Navbar";
// import "../../pages/ViewDetails.css";

// const packageDetails = {
//   // 1: {
//   //   name: "KANDY Chill & Wild ",
//   //   title: "1 -- KANDY Chill & Wild ",
//   //   desc: [
//   //     "Day 1: Pick up from Bandaranaike International Airport (BIA)", 
//   //    "Day 2: Visit Ambuluwawa Tower, a multi-religious site with panoramic views.", 
//   //    "Day 3: Kandy - Ella", 
//   //    "Day 4: Ella - Mirissa", 
//   //    "Day 5: Mirissa - Bentota"],

//   //   activities: ["Sigiriya Rock", "Temple of Tooth", "Ravana Falls", "Nine Arch Bridge", "Whale Watching"],
//   // },
//   // 2: {
//   //   name: "Historical & Chill",
//   //   title: "2 -- Historical & Chill",
//   //   desc: ["Day 1: Historical & Chill -- BIA - Dambulla", "Day 2: Dambulla - Kandy", "Day 3: Kandy - Nuwara Eliya", "Day 4: Nuwara Eliya - Bentota"],
//   //   activities: ["Pinnawala Elephant Orphanage", "Traditional Kandy Dance", "Ambuluwawa Tower", "Bentota Turtle Hatchery"],
//   // },
//   //   3: {
//   //   name: "Chill & Wild",
//   //   title: "3 -- Chill & Wild",
//   //   desc: ["Day 1: Chill & Wild -- BIA - Kandy", "Day 2: Kandy - Nuwara Eliya", "Day 3: Nuwara Eliya - Ella", "Day 4: Ella - Udawalawa"],
//   //   activities: ["Tea Factory Visit", "Ashoka Vatika Temple", "Udawalawa Safari", "Ravana Falls", "Nine Arch Bridge"],
//   //   },

//     1: {
//       // id: "Srilanka_one",
//       title: "1 -- Sri Lanka Ancient Wonders",
//       city: "Srilanka",
//       distance: 300,
//       price: 109021,
//       address: "Srilanka",
//       packages: "6 Nights/7 days",
//       maxGroupSize: 2,
//       person: "/2 adults",
//       desc: [
//         { day: "Day 1", details: "Upon your arrival at Bandaranaike International Airport..." },
//         { day: "Day 2", details: "After indulging in a hearty breakfast, embark on a scenic journey..." },
//         { day: "Day 3", details: "Start your day with a delicious breakfast before diving into..." },
//         { day: "Day 4", details: "After a rejuvenating breakfast, depart for the coastal paradise..." },
//         { day: "Day 5", details: "Explore the enchanting attractions of Mirissa after breakfast..." },
//         { day: "Day 6", details: "Embark on an enchanting journey to Bentota after breakfast..." },
//         { day: "Day 7", details: "After a leisurely breakfast, bid farewell to the picturesque landscapes..." },
//       ],
//       inclusion: [
//         "Private air-conditioned tourist vehicle with an English-speaking chauffeur guide for tours and transfers.",
//         "Accommodation: Double/Single/Triple rooms on Half Board Basis.",
//         "Free visits to Galle Fort, Mask factory, and other attractions.",
//         "Entrance fees to Madu River boat safari, Turtle Hatchery, and more.",
//       ],
//       exclusion: [
//         "Personal Expenses such as telephone calls, laundry, drinks, etc.",
//         "Lunch during the stay in Sri Lanka.",
//         "Tips and Porterage, Camera Permits for filming.",
//       ],
//       policy: [
//         "Full payment (100%) is required for bookings made in the same month.",
//         "Bookings within 2-3 months require a 25% payment of the total amount.",
//         "Bookings exceeding 4 months necessitate a 10% payment upfront.",
//         "Full settlement must occur 14 days before the arrival date.",
//       ],
//       avgRating: 4.5,
//     },
    // 2: {
    //   // id: "Srilanka_one",
    //   title: "2 -- Sri Lanka Ancient Wonders",
    //   city: "Srilanka",
    //   distance: 300,
    //   price: 109021,
    //   address: "Srilanka",
    //   packages: "6 Nights/7 days",
    //   maxGroupSize: 2,
    //   person: "/2 adults",
    //   desc: [
    //     { day: "Day 1", details: "Upon your arrival at Bandaranaike International Airport..." },
    //     { day: "Day 2", details: "After indulging in a hearty breakfast, embark on a scenic journey..." },
    //     { day: "Day 3", details: "Start your day with a delicious breakfast before diving into..." },
    //     { day: "Day 4", details: "After a rejuvenating breakfast, depart for the coastal paradise..." },
    //     { day: "Day 5", details: "Explore the enchanting attractions of Mirissa after breakfast..." },
    //     { day: "Day 6", details: "Embark on an enchanting journey to Bentota after breakfast..." },
    //     { day: "Day 7", details: "After a leisurely breakfast, bid farewell to the picturesque landscapes..." },
    //   ],
    //   inclusion: [
    //     "Private air-conditioned tourist vehicle with an English-speaking chauffeur guide for tours and transfers.",
    //     "Accommodation: Double/Single/Triple rooms on Half Board Basis.",
    //     "Free visits to Galle Fort, Mask factory, and other attractions.",
    //     "Entrance fees to Madu River boat safari, Turtle Hatchery, and more.",
    //   ],
    //   exclusion: [
    //     "Personal Expenses such as telephone calls, laundry, drinks, etc.",
    //     "Lunch during the stay in Sri Lanka.",
    //     "Tips and Porterage, Camera Permits for filming.",
    //   ],
    //   policy: [
    //     "Full payment (100%) is required for bookings made in the same month.",
    //     "Bookings within 2-3 months require a 25% payment of the total amount.",
    //     "Bookings exceeding 4 months necessitate a 10% payment upfront.",
    //     "Full settlement must occur 14 days before the arrival date.",
    //   ],
    //   avgRating: 4.5,
    // },

    // 3: {
    //   id: "Srilanka_one",
    //   title: "3 -- Sri Lanka Ancient Wonders",
    //   city: "Srilanka",
    //   distance: 300,
    //   price: 109021,
    //   address: "Srilanka",
    //   packages: "6 Nights/7 days",
    //   maxGroupSize: 2,
    //   person: "/2 adults",
    //   desc: [
    //     { day: "Day 1", details: "Upon your arrival at Bandaranaike International Airport..." },
    //     { day: "Day 2", details: "After indulging in a hearty breakfast, embark on a scenic journey..." },
    //     { day: "Day 3", details: "Start your day with a delicious breakfast before diving into..." },
    //     { day: "Day 4", details: "After a rejuvenating breakfast, depart for the coastal paradise..." },
    //     { day: "Day 5", details: "Explore the enchanting attractions of Mirissa after breakfast..." },
    //     { day: "Day 6", details: "Embark on an enchanting journey to Bentota after breakfast..." },
    //     { day: "Day 7", details: "After a leisurely breakfast, bid farewell to the picturesque landscapes..." },
    //   ],
    //   inclusion: [
    //     "Private air-conditioned tourist vehicle with an English-speaking chauffeur guide for tours and transfers.",
    //     "Accommodation: Double/Single/Triple rooms on Half Board Basis.",
    //     "Free visits to Galle Fort, Mask factory, and other attractions.",
    //     "Entrance fees to Madu River boat safari, Turtle Hatchery, and more.",
    //   ],
    //   exclusion: [
    //     "Personal Expenses such as telephone calls, laundry, drinks, etc.",
    //     "Lunch during the stay in Sri Lanka.",
    //     "Tips and Porterage, Camera Permits for filming.",
    //   ],
    //   policy: [
    //     "Full payment (100%) is required for bookings made in the same month.",
    //     "Bookings within 2-3 months require a 25% payment of the total amount.",
    //     "Bookings exceeding 4 months necessitate a 10% payment upfront.",
    //     "Full settlement must occur 14 days before the arrival date.",
    //   ],
    //   avgRating: 4.5,
    // },
    // 4: {
    //   // id: "Srilanka_one",
    //   title: "4 -- Sri Lanka Ancient Wonders",
    //   city: "Srilanka",
    //   distance: 300,
    //   price: 109021,
    //   address: "Srilanka",
    //   packages: "6 Nights/7 days",
    //   maxGroupSize: 2,
    //   person: "/2 adults",
    //   desc: [
    //     { day: "Day 1", details: "Upon your arrival at Bandaranaike International Airport..." },
    //     { day: "Day 2", details: "After indulging in a hearty breakfast, embark on a scenic journey..." },
    //     { day: "Day 3", details: "Start your day with a delicious breakfast before diving into..." },
    //     { day: "Day 4", details: "After a rejuvenating breakfast, depart for the coastal paradise..." },
    //     { day: "Day 5", details: "Explore the enchanting attractions of Mirissa after breakfast..." },
    //     { day: "Day 6", details: "Embark on an enchanting journey to Bentota after breakfast..." },
    //     { day: "Day 7", details: "After a leisurely breakfast, bid farewell to the picturesque landscapes..." },
    //   ],
    //   inclusion: [
    //     "Private air-conditioned tourist vehicle with an English-speaking chauffeur guide for tours and transfers.",
    //     "Accommodation: Double/Single/Triple rooms on Half Board Basis.",
    //     "Free visits to Galle Fort, Mask factory, and other attractions.",
    //     "Entrance fees to Madu River boat safari, Turtle Hatchery, and more.",
    //   ],
    //   exclusion: [
    //     "Personal Expenses such as telephone calls, laundry, drinks, etc.",
    //     "Lunch during the stay in Sri Lanka.",
    //     "Tips and Porterage, Camera Permits for filming.",
    //   ],
    //   policy: [
    //     "Full payment (100%) is required for bookings made in the same month.",
    //     "Bookings within 2-3 months require a 25% payment of the total amount.",
    //     "Bookings exceeding 4 months necessitate a 10% payment upfront.",
    //     "Full settlement must occur 14 days before the arrival date.",
    //   ],
    //   avgRating: 4.5,
    // },

//     5: {
//       id: "Srilanka_one",
//       title: "5 -- Sri Lanka Ancient Wonders",
//       city: "Srilanka",
//       distance: 300,
//       price: 109021,
//       address: "Srilanka",
//       packages: "6 Nights/7 days",
//       maxGroupSize: 2,
//       person: "/2 adults",
//       desc: [
//         { day: "Day 1", details: "Upon your arrival at Bandaranaike International Airport..." },
//         { day: "Day 2", details: "After indulging in a hearty breakfast, embark on a scenic journey..." },
//         { day: "Day 3", details: "Start your day with a delicious breakfast before diving into..." },
//         { day: "Day 4", details: "After a rejuvenating breakfast, depart for the coastal paradise..." },
//         { day: "Day 5", details: "Explore the enchanting attractions of Mirissa after breakfast..." },
//         { day: "Day 6", details: "Embark on an enchanting journey to Bentota after breakfast..." },
//         { day: "Day 7", details: "After a leisurely breakfast, bid farewell to the picturesque landscapes..." },
//       ],
//       inclusion: [
//         "Private air-conditioned tourist vehicle with an English-speaking chauffeur guide for tours and transfers.",
//         "Accommodation: Double/Single/Triple rooms on Half Board Basis.",
//         "Free visits to Galle Fort, Mask factory, and other attractions.",
//         "Entrance fees to Madu River boat safari, Turtle Hatchery, and more.",
//       ],
//       exclusion: [
//         "Personal Expenses such as telephone calls, laundry, drinks, etc.",
//         "Lunch during the stay in Sri Lanka.",
//         "Tips and Porterage, Camera Permits for filming.",
//       ],
//       policy: [
//         "Full payment (100%) is required for bookings made in the same month.",
//         "Bookings within 2-3 months require a 25% payment of the total amount.",
//         "Bookings exceeding 4 months necessitate a 10% payment upfront.",
//         "Full settlement must occur 14 days before the arrival date.",
//       ],
//       avgRating: 4.5,
//     },
// };

// const PackageDetails = () => {
//   const { id } = useParams();
//   const details = packageDetails[id];

//   return (
//     <>
//     <Navbar />
//     <div className="sample-package-details">
//       {/* <Link to="/" style={{ textDecoration: "none", color: "blue" }}>
//         Back to Home
//       </Link> */}

//       <div className="package-content">

//       {/* <h1>{details.name}</h1> */}
//       <h1>{details.title}</h1>
      
//       <h3>Itinerary</h3>
//       <ul>
//         {details.desc.map((day, index) => (
//           <li key={index}>{day}</li>
//         ))}
//       </ul>

//       <h3>Inclusions</h3>
//       <ul>
//         {details.inclusion.map((activity, index) => (
//           <li key={index}>{activity}</li>
//         ))}
//       </ul>

//       <h3>Exclusions</h3>
//       <ul>
//         {details.exclusion.map((activity, index) => (
//           <li key={index}>{activity}</li>
//         ))}
//       </ul>

//       <h3>Policy</h3>
//       <ul>
//       {details.policy.map((activity, index) => (
//           <li key={index}>{activity}</li>
//         ))}
//       </ul>
//       </div>
//     </div>


//     </>
//   );
// };

// export default PackageDetails;


import React, { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import emailjs from "@emailjs/browser";
import Navbar from "../Navbar";
import "./PackageDetails.css";
import "../../pages/ViewDetails.css";

// import { Image } from "react-bootstrap";

import package1 from "../../assets/images/packages/1.png";
import package2 from "../../assets/images/packages/2.png";
import package3 from "../../assets/images/packages/3.png";
import package4 from "../../assets/images/packages/4.png";
import package5 from "../../assets/images/packages/5.png";
import GetCallBack from "../GetCallBack";

const packageDetails = {
  1: {
    title: "KANDY - NUWARA ELIYA Chill & Wild | Kandy: A Jewel in the Heart of Sri Lank",
    city: "Srilanka",
    distance: 300,
    price: 109021,
    address: "Srilanka",
    packages: "4 Nights/5 days",
    maxGroupSize: 2,
    image:[{package1}],
    person: "/2 adults",
    desc: [
      { day: "Day 1 : PICK UP FROM BIA , PINNAWALA ELEPHANT ORPHANAGE,TEMPLE OF TOOTH, TRADITIONAL", details: "Pinnawala Elephant Orphanage:-An Elephant orphanage, nursery and captive breeding ground for wild Asian elephants. The orphanage was established to feed, nurse and house young/baby elephants found abandoned by their mothers. You will be able to feed them according to their time table. It is recommended to visit an Herbal Spice Garden on your way to Kandy to learn about Sri Lanka’s world renowned spices and a lot of different herbs Temple of the Tooth:-The Temple of the Sacred Tooth Relic is a worldrenowned place of worship, where the left Canine tooth of Gautama Buddha is enshrined. The temple which is venerated by thousands of local & foreign devotees and tourists daily was named as a world heritage by UNESCO in 1988.  Traditional dance show:-The Kandy Cultural Dance Show comprises Sri Lankan arts, dances, and cultural heritage. So, this is an event that describes the rich culture of this paradise island. Normally, the dance show starts sharply at 5 p.m. every day. The event includes many types of Sri Lankan traditional dances." },
      { day: "Day 2 : AMBULUWAWA, HANUMAN TEMPLE, TEA FACTORY, ASHOK VATIKA KOVIL ,GREGORY LAKE", details: "Ambuluwawa Tower:- is the first Multi Religious Center in Sri Lanka. You can see a Temple, Kovil, Mosque and a Church. It is known that Ambuluwawa Temple is an evocative of a Buddhist 'stupa' with a height of 48 meter Tea factory:- around Ramboda will be a fantastic stop through misty mountains as you can enjoy with a cup of tea (world famous “Ceylon Tea”) and also you can buy fresh products from their outlet. Learn the traditional process of fermenting Tea leaf. Enjoy a cup of fresh Ceylon Tea. Tea was first introduced here by the Great Britain’s. You will be able to witness beautiful Tea Plantations and Tea-puckers work on the slopes in their traditional attire. Sri Bhakta Hanuman Temple:- One of the important Hanuman Temples in Sri Lanka located on summit of the very beautiful picturesque mountain in Ramboda 30 KM north to Nuwara Eliya, the gateway to the tea country hill station of Sri Lanka. This Hanuman Temple in Ramboda was constructed by Chinmaya Mission of Sri Lanka. Lake Gregory:- sometimes also called Gregory Lake or Gregor Reservoiris a reservoir,is a reservoir in theheart of the tea country hill city of NuwaraEliya in Sri Lanka. Lake Gregory was createdduring the period of British Governor SirWilliam Gregory in 1873. The lake and thesurrounding area make up the Gregory LakeArea. Ashoka Vatika (Seetha Amman Temple):-The Seetha Amman Temple is a Hindu temple located in Seetha Eliya village approximately 1 kilometer from Hakgala Botanical Garden and 5 kilometers from Nuwara Eliya." },
      { day: "Day 3 : 9 ARCH ,RAWANA FALLS", details: "The Nine Arch Bridge (Sinhala: ආරු ක්කු නමයේ පා ලම; Tamil: ஒன்்பன்் து வளை வுகை்் பா லம்்)ம்் also called the Bridge in the Sky,[4] is a viaduct bridge in Sri Lanka and one of the best examples of colonial-era railway construction in the country. Ravana Falls:- It is a popular sightseeing attraction in Uva province of Sri Lanka. It currently ranks as one of the widest falls in the country.This waterfall measures approximately 25 m (82 ft) in height and cascades from an oval-shaped concave rock outcrop. During the local wet season, the waterfall turns into what is said to resemble an areca flower with withering petals " },
      { day: "Day 4 : BIA", details: "Udawalawe National Park, elephants are everywhere. They roam in herds of up to 30 and total up to 600 in the park, which means a visit here is almost guarantee of sightings. There are also leopards, crocodiles, water buffalo, deer, and all types of bird species, but it’s the elephant that’s the star of the show here." },
      { day: "Day 5 : BIA", details: "BIA ( IF POSSSIBLE COLOMBO CITY TOUR & SHOPPING ON THE WAY TO AIRPORT)" },
      
    ],
    overview: [
       "Kandy is a cultural and scenic city in central Sri Lanka, known for its beautiful lake, ancient temples, and the iconic Temple of the Tooth, a UNESCO World Heritage site."
    ],
    inclusion: [
      "•	accomodation at a selected room type on double sharing basis",
      "•	transportation",
      "• meet an greet by our representative",
      "•	transportation by air-conditioned vehicle",
      "•	service of an english speaking driver",
    ],
    exclusion: [
      "• International Air Fare/ Visa Fees",
      "• Village safari",
      "• Lunch & Dinner",
      "• Any nature of personal expences which are not on the inclusions",
      "• Tips & Any kind of personal expenses",
      "• PCR , and Travel insurance payment",
      "• Permits for photos / videos, tips and extras where applicable.",
      "• Optional excursions and additional services not covered by the program",
      "• Any other service not included in the itinerary.",      
    ],
    policy: [
      "• Full payment (100%) is required for bookings made in the same month.",
      "• Bookings within 2-3 months require a 25% payment of the total amount.",
      "• Bookings exceeding 4 months necessitate a 10% payment upfront.",
      "• Full settlement must occur 14 days before the arrival date.",
    ],
    avgRating: 4.5,
  },

  2: {
    // id: "Srilanka_one",
    title: "ELLA – MIRISSA Hills & Sand",
    city: "Srilanka",
    distance: 300,
    price: 109021,
    address: "Srilanka",
    packages: "4 Nights/5 days",
    maxGroupSize: 2,
    image:[{package2}],
    person: "/2 adults",
    desc: [
      { day: "Day 1 : PICK UP FROM BIA, PINNAWALA ELEPHANT ORPHANAGE,SIGIRIYA ROCK", details: "Pinnawala Elephant Orphanage:-An Elephant orphanage, nursery and captive breeding ground for wild Asian elephants. The orphanage was established to feed, nurse and house young/baby elephants found abandoned by their mothers. You will be able to feed them according to their time table. It is recommended to visit an Herbal Spice Garden on your way to Kandy to learn about Sri Lanka’s world renowned spices and a lot of different herbs Sigiriya - or Sinhagiri (Lion Rock )is an ancient rock fortress located in the northern Matale District near the town of Dambulla in the Central Province, Sri Lanka. It is a site of historical and archaeological significance that is dominated by a massive column of granite approximately 180 m (590 ft) high " },
      { day: "Day 2 : TEMPLE OF TOOTH, TRADITIONAL KANDYAN DANCE,BATIK", details: "Temple of the Tooth:-The Temple of the Sacred Tooth Relic is a worldrenowned place of worship, where the left Canine tooth of Gautama Buddha is enshrined. The temple which is venerated by thousands of local & foreign devotees and tourists daily was named as a world heritage by UNESCO in 1988 Traditional dance show:-The Kandy Cultural Dance Show comprises Sri Lankan arts, dances, and cultural heritage. So, this is an event that describes the rich culture of this paradise island. Normally, the dance show starts sharply at 5 p.m. every day. The event includes many types of Sri Lankan traditional dances.  Batik :- It is an ancient fabric wax-resist dyeing tradition of Java, Indonesia. The art of batik is most highly developed and some of the best batiks in the world still made there. In Java, all the materials for the process are readily available cotton and beeswax and plants from which different vegetable dyes are made. " },
      { day: "Day 3 : ASHOKA VATIKA ,AMBULUWAWA TOWER , TEA FACTORY ,LAKE GREGORY", details: "Ashoka Vatika (Seetha Amman Temple):-The Seetha Amman Temple is a Hindu temple located in Seetha Eliya village approximately 1 kilometer from Hakgala Botanical Garden and 5 kilometers from Nuwara Eliya. . Ambuluwawa Tower:- is the first Multi Religious Center in Sri Lanka. You can see a Temple, Kovil, Mosque and a Church. It is known that Ambuluwawa Temple is an evocative of a Buddhist 'stupa' with a height of 48 meters.  Tea factory:- around Ramboda will be a fantastic stop through misty mountains as you can enjoy with a cup of tea (world famous “Ceylon Tea”) and also you can buy fresh products from their outlet. Learn the traditional process of fermenting Tea leaf. Enjoy a cup of fresh Ceylon Tea. Tea was first introduced here by the Great Britain’s. You will be able to witness beautiful Tea Plantations and Tea-puckers work on the slopes in their traditional attire. Lake Gregory:- sometimes also called Gregory Lake or Gregor Reservoiris a reservoir,is a reservoir in theheart of the tea country hill city of NuwaraEliya in Sri Lanka. Lake Gregory was createdduring the period of British Governor SirWilliam Gregory in 1873. The lake and thesurrounding area make up the Gregory LakeArea " },
      { day: "Day 4 : GALLE FORT ,MADU RIVER BOAT SAFARI ,TURTLE HATCHERY", details: "The Nine Arch Bridge :-(Sinhala: ආරු ක්කු නමයේ පා ලම; Tamil: ஒன்்பன்் து வளை வுகை்் பா லம்்)ம்் also called the Bridge in the Sky,[4] is a viaduct bridge in Sri Lanka and one of the best examples of colonial-era railway construction in the country. Ravana Falls:- (Sinhala: රා වණා ඇල්ල, romanized: Ravana Ella) is a popular sightseeing attraction in Uva province of Sri Lanka. It currently ranks as one of the widest falls in the country.This waterfall measures approximately 25 m (82 ft) in height and cascades from an oval-shaped concave rock outcrop. During the local wet season, the waterfall turns into what is said to resemble an areca flower with withering petals. But this is not the case in the dry season, where the flow of water reduces dramatically. The falls form part of the Ravana Ella Wildlife Sanctuary, and are located 6 km (3.7 mi) away from the local railway station at Ella. Udawalawe National Park:- elephants are everywhere. They roam in herds of up to 30 and total up to 600 in the park, which means a visit here is almost guarantee of sightings. There are also leopards, crocodiles, water buffalo, deer, and all types of bird species, but it’s the elephant that’s the star of the show here." },
      { day: "Day 5 : DOLPHIN & WHALE WATCHING ,GALLE FORT , TURTLE HATCHERY ,UNAWATUNA BEACH", details: "Mirissa - is the ideal place for whale and dolphin watching between the months of November to April, as the waters become a playground for a myriad of whales and dolphin species. Galle Fort :-(Sinhala: ගා ලු ය ටු ාා ව Galu Kotuwa; Tamil: கா லிக்் ககா டை்் ட, romanized: Kālik Kōṭṭai), in the Bay of Galle on the southwest coast of Sri Lanka, was built first in 1588 by the Portuguese, then extensively fortified by the Dutch during the 17th century from 1649 onwards. It is a historical, archaeological and architectural heritage monument, which even after more than 432 years maintains a polished appearance, due to extensive reconstruction work done by the Archaeological Department of Sri Lanka. The Bentota Turtle Hatchery:- Project is a popular attraction in Bentota for all travelers. The pristine golden shores that draws you to our island, also provides an enticing nesting ground for several endangered turtle species from the Olive Ridley to the Leatherback turtle.  Unawatuna Beach :- One of the best beaches in Galle, Unawatuna Beach could be your ultimate spot to relax and admit the natural beauty of Galle. While it is not ideal for surfing, it is a great location if you want to go swimming because of the calm waters. If you are interested in snorkelling or other water sports, you can head to Jungle Beach which is located only a short walk away. This is one of the day outing places in Galle where you can visit with your family." },
      { day: "Day 6 : BENOTA - BIA", details: "BENOTA -- BIA" },
    ],
    overview: [
      "Bentota is a coastal town in Sri Lanka, famous for its pristine beaches, water sports, and luxury resorts. Its also home to the Bentota River and beautiful botanical gardens."
   ],
    inclusion: [
      "• Accomodation at a selected room type on double sharing basis",
      "• Transportation",
      "• Meet an greet by our representative",
      "• Transportation by air-conditioned vehicle",
      "• Service of an english speaking driver",
     
    ],
    exclusion: [
      "• International Air Fare/ Visa Fees",
      "• Village safari",
      "• Lunch & Dinner",
      "• Any nature of personal expences which are not on the inclusions",
      "• Tips & Any kind of personal expenses",
      "• PCR , and Travel insurance payment",
      "• Permits for photos / videos, tips and extras where applicable.",
      "• Optional excursions and additional services not covered by the program",
      "• Any other service not included in the itinerary.",
    ],
    policy: [
      "Full payment (100%) is required for bookings made in the same month.",
      "Bookings within 2-3 months require a 25% payment of the total amount.",
      "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      "Full settlement must occur 14 days before the arrival date.",
    ],
    avgRating: 4.5,
  },

  3: {
    id: "Srilanka_one",
    title: "NUWARA ELIYA – BENTOTA Historical & Chill | Discover the Charm of Nuwara Eliya",
    city: "Srilanka",
    distance: 300,
    price: 109021,
    address: "Srilanka",
    packages: "6 Nights/7 days",
    maxGroupSize: 2,
    image:[{package3}],
    person: "/2 adults",
    desc: [
      { day: "Day 1 : : PICK UP FROM BIA, PINNAWALA ELEPHANT ORPHANAGE,ROYAL ROCK TEMPLE", details: "Pinnawala Elephant Orphanage:-An Elephant orphanage, nursery and captive breeding ground for wild Asian elephants. The orphanage was established to feed, nurse and house young/baby elephants found abandoned by their mothers. You will be able to feed them according to their time table. It is recommended to visit an Herbal Spice Garden on your way to Kandy to learn about Sri Lanka’s world renowned spices and a lot of different herbs.  The beautiful Royal Rock Temple :- complex sits about 160m above the road in the southern part of Dambulla. Five separate caves contain about 150 absolutely stunning Buddha statues and paintings, some of Sri Lanka's most important and evocative religious art " },
      { day: "Day 2 : SIGIRIYA ROCK, TEMPLE OF TOOTH, TRADITIONAL KANDYAN DANCE,ROYAL BOTANICAL GARDEN, BATIK GEM", details: "Sigiriya - or Sinhagiri (Lion Rock )is an ancient rock fortress located in the northern Matale District near the town of Dambulla in the Central Province, Sri Lanka. It is a site of historical and archaeological significance that is dominated by a massive column of granite approximately 180 m (590 ft) high Temple of the Tooth:-The Temple of the Sacred Tooth Relic is a worldrenowned place of worship, where the left Canine tooth of Gautama Buddha is enshrined. The temple which is venerated by thousands of local & foreign devotees and tourists daily was named as a world heritage by UNESCO in 1988 Traditional dance show:-The Kandy Cultural Dance Show comprises Sri Lankan arts, dances, and cultural heritage. So, this is an event that describes the rich culture of this paradise island. Normally, the dance show starts sharply at 5 p.m. every day. The event includes many types of Sri Lankan traditional dances. Royal Botanic Gardens:-Peradeniya are about 5.5 km to the west of the city of Kandy in the Central Province of Sri Lanka. In 2016, the garden was visited by 1.2 million locals and 400,000 foreign visitors.  Batik :- It is an ancient fabric wax-resist dyeing tradition of Java, Indonesia. The art of batik is most highly developed and some of the best batiks in the world still made there. In Java, all the materials for the process are readily available cotton and beeswax and plants from which different vegetable dyes are made. " },
      { day: "Day 3 : AMBULUWAWA, HANUMAN TEMPLE, TEA FACTORY, ASHOK VATIKA KOVIL ,GREGORY LAKE", details: "Ambuluwawa Tower:- is the first Multi Religious Center in Sri Lanka. You can see a Temple, Kovil, Mosque and a Church. It is known that Ambuluwawa Temple is an evocative of a Buddhist 'stupa' with a height of 48 meters. Tea factory:- around Ramboda will be a fantastic stop through misty mountains as you can enjoy with a cup of tea (world famous “Ceylon Tea”) and also you can buy fresh products from their outlet. Learn the traditional process of fermenting Tea leaf. Enjoy a cup of fresh Ceylon Tea. Tea was first introduced here by the Great Britain’s. You will be able to witness beautiful Tea Plantations and Tea-puckers work on the slopes in their traditional attire. Sri Bhakta Hanuman Temple:- One of the important Hanuman Temples in Sri Lanka located on summit of the very beautiful picturesque mountain in Ramboda 30 KM north to Nuwara Eliya, the gateway to the tea country hill station of Sri Lanka. This Hanuman Temple in Ramboda was constructed by Chinmaya Mission of Sri Lanka Lake Gregory:- sometimes also called Gregory Lake or Gregor Reservoiris a reservoir,is a reservoir in theheart of the tea country hill city of NuwaraEliya in Sri Lanka. Lake Gregory was createdduring the period of British Governor SirWilliam Gregory in 1873. The lake and thesurrounding area make up the Gregory LakeArea.  Ashoka Vatika (Seetha Amman Temple):-The Seetha Amman Temple is a Hindu temple located in Seetha Eliya village approximately 1 kilometer from Hakgala Botanical Garden and 5 kilometers from Nuwara Eliya. " },
      { day: "Day 4 : TURTLE HATCHERY, MADU RIVER SAFARI , BENTOTA BEACH", details: "The Bentota Turtle Hatchery:- Project is a popular attraction in Bentota for all travelers. The pristine golden shores that draws you to our island, also provides an enticing nesting ground for several endangered turtle species from the Olive Ridley to the Leatherback turtle. River Madu on a motorboat safari. • Discover the wildlife and rural ways of the River Madu on a motorboat safari. • Hassle-free way to experience the ecosystem and communities along the river. • Sail by mangroves and spot reptiles, monkeys, cormorants, and other species. • Visit a monastery and fish pedicure pond, and see how locals use cinnamon." },
      { day: "Day 5 : BIA", details: "BIA ( IF POSSSIBLE COLOMBO CITY TOUR & SHOPPING ON THE WAY TO AIRPORT)" },
      
    ],
    overview: [
      "Nuwara Eliya, located in Sri Lankas central highlands, is known for its cool climate, lush tea plantations, and scenic landscapes. Often called Little England, it offers colonial charm and natural beauty."
   ],
   inclusion: [
    "• Accomodation at a selected room type on double sharing basis",
    "• Transportation",
    "• Meals and activites as mentioned in the itinary",
    "• Meet an greet by our representative",
    "• Transportation by air-conditioned vehicle",
    "• Service of an english speaking driver",
  ],
  exclusion: [
    "• International Air Fare/ Visa Fees",
    "• Village safari",
    "• Lunch & Dinner",
    "• Any nature of personal expences which are not on the inclusions",
    "• Tips & Any kind of personal expenses",
    "• PCR , and Travel insurance payment",
    "• Permits for photos / videos, tips and extras where applicable.",
    "• Optional excursions and additional services not covered by the program.",
    "• Any other service not included in the itinerary.",
  ],
    policy: [
      "Full payment (100%) is required for bookings made in the same month.",
      "Bookings within 2-3 months require a 25% payment of the total amount.",
      "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      "Full settlement must occur 14 days before the arrival date.",
    ],
    avgRating: 4.5,
  },

  4: {
    // id: "Srilanka_one",
    title: "DAMBULLA Historical & Beach | The Jewel of Sri Lankas Cultural Heritage",
    city: "Sri Lanka",
    place: "DAMBULLA - KANDY ",
    distance: 300,
    price: 109021,
    address: "Srilanka",
    packages: "4 Nights/5 days",
    maxGroupSize: 2,
    image:[{package4}],
    person: "/2 adults",
    desc: [
      { day: "Day 1 : PICK UP FROM BIA, PINNAWALA ELEPHANT ORPHANAGE,ROYAL ROCK TEMPLE", details: "Pinnawala Elephant Orphanage:-An Elephant orphanage, nursery and captive breeding ground for wild Asian elephants. The orphanage was established tofeed, nurse and house young/baby elephants found abandoned by their mothers.You will be able to feed them according to their time table. It is recommended to visit an Herbal Spice Garden on your way to Kandy to learn about Sri Lankas world renowned spices and a lot of different herbs. The beautiful Royal Rock Temple :- complex sits about 160m above the road in the southern part of Dambulla. Five separate caves contain about 150 absolutely stunning Buddha statues and paintings, some of Sri Lankas most important and evocative religious art" },
      { day: "Day 2 : SIGIRIYA ROCK, TEMPLE OF TOOTH, TRADITIONAL KANDYAN DANCE,", details: "Sigiriya - or Sinhagiri (Lion Rock )is an ancient rock fortress located in thenorthern Matale District near the town of Dambulla in the CentralProvince, Sri Lanka. It is a site of historical and archaeological significancethat is dominated by a massive column of granite approximately 180 m(590 ft) hig, Temple of the Tooth:-The Temple of the Sacred Tooth Relic is a worldrenowned place of worship, where the left Canine tooth of Gautama Buddha is enshrined. The temple which is venerated by thousands of local & foreign devotees and tourists daily was named as a world heritage by UNESCO in 1988. Sigiriya - or Sinhagiri (Lion Rock )is an ancient rock fortress located in the northern Matale District near the town of Dambulla in the Central Province, Sri Lanka. It is a site of historical and archaeological significance that is dominated by a massive column of granite approximately 180 m (590 ft) high Traditional dance show:-The Kandy Cultural Dance Show comprises Sri Lankan arts, dances, and cultural heritage. So, this is an event that describes the rich culture of this paradise island. Normally, the dance show starts sharply at 5 p.m. every day. The event includes many types of Sri Lankan traditional dances. " },
      { day: "Day 3 : GALLE FORT ,MADU RIVER BOAT SAFARI ,TURTLE HATCHERY", details: "Galle Fort :-(Sinhala: ගා ලු ය ටු ාා ව Galu Kotuwa; Tamil: கா லிக்் ககா டள்் ட, romanized: Kālik Kōṭṭai), in the Bay of Galle on the southwest coast of Sri Lanka, was built first in 1588 by the Portuguese, then extensively fortified by the Dutch during the 17th century from 1649 onwards. It is a historical, archaeological and architectural heritage monument, which even after more than 432 years maintains a polished appearance, due to extensive reconstruction work done by the Archaeological Department of Sri Lanka.  River Madu on a motorboat safari. • Discover the wildlife and rural ways of the River Madu on a motorboat safari. • Hassle-free way to experience the ecosystem and communities along the river. • Sail by mangroves and spot reptiles, monkeys, cormorants, and other species. • Visit a monastery and fish pedicure pond, and see how locals use cinnamon." },
      { day: "Day 4 : LOTUS TOWER,GANGARAMAYA TEMPLE ,PORT CITY ,NEGOMBO BEACH", details: "Lotus Tower : - is a 351.5 m (1,153 ft) tall tower, located in Colombo, SriLanka.It has been called a symbolic landmark of Sri Lanka. As of 2019, the toweris the tallest self-supported structure in South Asia; the second tallest structure in South Asia after the guy-wire-supported INS Kattabomman in India; the 11th tallest tower in Asia and the 19th tallest tower in the world. It was first proposed to be built in the suburb of Peliyagoda but later the Government of Sri Lanka decided to change the location.The lotus-shaped tower is used for communication, observation and other leisure facilities. Construction is estimated to have cost US$113 million Gangaramaya Temple - (Sinhala: ශ්‍රී ගං ගා රා ම මහා විහා රය śrī gangārāma mahāvihāraya, Tamil:ஸ்ரீகங்்காங்் காரா ம மகா வி கா ரம்் Srī Gaṅgārāma Makāvikāram) is one of the most important temples in Colombo, Sri Lanka, being a mix of modern architecture and cultural essence. Located on the Beira Lake, it was completed in the late 19th century. Port City Colombo, is a multi-services special economic zone located in Colombo, Sri Lanka, which is currently under construction on reclaimed land adjacent to the Galle Face Green. The land reclamation work had been completed as of January 2019. In 2017, the cost was slated to be US$ 15 billion." },
      { day: "Day 5 : NEGOMBO - BIA", details: "Explore the enchanting attractions of Mirissa after breakfast..." },
      
    ],
    overview: [
      "Dambulla, a historic city in Sri Lanka, is renowned for the Dambulla Cave Temple, a UNESCO World Heritage site. It features stunning ancient frescoes and statues of Buddha in serene surroundings."
   ],
    inclusion: [
      "• Accomodation at a selected room type on double sharing basis",
      "• Transportation",
      "• Meals and activites as mentioned in the itinary",
      "• Meet an greet by our representative",
      "• Transportation by air-conditioned vehicle",
      "• Service of an english speaking driver",
    ],
    exclusion: [
      "• International Air Fare/ Visa Fees",
      "• Village safari",
      "• Lunch & Dinner",
      "• Any nature of personal expences which are not on the inclusions",
      "• Tips & Any kind of personal expenses",
      "• PCR , and Travel insurance payment",
      "• Permits for photos / videos, tips and extras where applicable.",
      "• Optional excursions and additional services not covered by the program.",
      "• Any other service not included in the itinerary.",
    ],
    policy: [
      "• Full payment (100%) is required for bookings made in the same month.",
      "• Bookings within 2-3 months require a 25% payment of the total amount.",
      "• Bookings exceeding 4 months necessitate a 10% payment upfront.",
      "• Full settlement must occur 14 days before the arrival date.",
    ],
    avgRating: 4.5,
  },

  5: {
    // id: "Srilanka_one",
    title: "MIRISSA – NEGOMBO Wild & Beach | Wild Encounters and Coastal Bliss",
    city: "Sri Lanka",
    distance: 300,
    price: 109021,
    address: "Srilanka",
    packages: "4 Nights/5 days",
    maxGroupSize: 2,
    image:[{package5}],
    person: "/2 adults",
    desc: [
      { day: "Day 1 : PICK UP FROM BIA ,BENTOTA BEACH , TURTLE HATCHERY,GALLE FORT", details: "Bentota Beach:- located on the southwestern coast of Sri Lanka, is a picturesque stretch of golden sand that embodies the true essence of a tropical paradise. With its lush greenery, swaying palm trees, and serene lagoon, Bentota Beach offers an unforgettable coastal experience for travelers of all ages and interests  The Bentota Turtle Hatchery:- Project is a popular attraction in Bentota for all travelers. The pristine golden shores that draws you to our island, also provides an enticing nesting ground for several endangered turtle species from the Olive Ridley to the Leatherback turtle.  Galle Fort :-(Sinhala: ගා ලු ය ටු ාා ව Galu Kotuwa; Tamil: கா லிக்் ககா டள்் ட, romanized: Kālik Kōṭṭai), in the Bay of Galle on the southwest coast of Sri Lanka, was built first in 1588 by the Portuguese, then extensively fortified by the Dutch during the 17th century from 1649 onwards. It is a historical, archaeological and architectural heritage monument, which even after more than 432 years maintains a polished appearance, due to extensive reconstruction work done by the Archaeological Department of Sri Lanka." },

      { day: "Day 2 : UDAWALAWA SAFARI", details: "Udawalawe National Park, elephants are everywhere. They roam in herds of up to 30 and total up to 600 in the park, which means a visit here is almost guarantee of sightings. There are also leopards, crocodiles, water buffalo, deer, and all types of bird species, but it’s the elephant that’s the star of the show here. " },
      { day: "Day 3 : DOLPHIN & WHALE WATCHING , MADU RIVER SAFARI", details: "Mirissa is the ideal place for whale and dolphin watching between the months of November to April, as the waters become a playground for a myriad of whales and dolphin species. River Madu on a motorboat safari. • Discover the wildlife and rural ways of the River Madu on a motorboat safari. • Hassle-free way to experience the ecosystem and communities along the river. • Sail by mangroves and spot reptiles, monkeys, cormorants, and other species. • Visit a monastery and fish pedicure pond, and see how locals use cinnamon." },
      { day: "Day 4 : LOTUS TOWER,GANGARAMAYA TEMPLE ,PORT CITY ,NEGOMBO BEACH", details: "Lotus Tower : - is a 351.5 m (1,153 ft) tall tower, located in Colombo, Sri Lanka.It has been called a symbolic landmark of Sri Lanka. As of 2019, the tower is the tallest self-supported structure in South Asia; the second tallest structure in South Asia after the guy-wire-supported INS Kattabomman in India; the 11th tallest tower in Asia and the 19th tallest tower in the world. It was first proposed to be built in the suburb of Peliyagoda but later the Government of Sri Lanka decided to change the location.The lotus-shaped tower is used for communication, observation and other leisure facilities. Construction is estimated to have cost US$113 million. Port City Colombo, is a multi-services special economic zone located in Colombo, Sri Lanka, which is currently under construction on reclaimed land adjacent to the Galle Face Green. The land reclamation work had been completed as of January 2019. In 2017, the cost was slated to be US$ 15 billion " },
      { day: "Day 5", details: "NEGOMBO -BIA" },
    ],

    overview: [
      "Udawalawe is a wildlife sanctuary in Sri Lanka, famous for its elephants. Mirissa, a coastal town, offers beautiful beaches, whale watching, and vibrant nightlife, making it a popular travel destination.",
    ],

    inclusion: [
      "• accomodation at a selected room type on double sharing basis",
      "• transportation",
      "• meet an greet by our representative",
      "• transportation by air-conditioned vehicle",
      "• service of an english speaking driver",
   ],

    exclusion: [
      "• International Air Fare/ Visa Fees",
      "• Village safari",
      "• Lunch & Dinner",
      "• Any nature of personal expences which are not on the inclusions",
      "• Tips & Any kind of personal expenses",
      "• PCR , and Travel insurance payment",
      "• Permits for photos / videos, tips and extras where applicable.",
      "• Optional excursions and additional services not covered by the program.",
      "• Any other service not included in the itinerary. ",
    ],

    policy: [
      "• Full payment (100%) is required for bookings made in the same month.",
      "• Bookings within 2-3 months require a 25% payment of the total amount.",
      "• Bookings exceeding 4 months necessitate a 10% payment upfront.",
      "• Full settlement must occur 14 days before the arrival date.",
    ],
    avgRating: 4.5,
  },
  
};

const PackageDetails = () => {
  // get call back code
  const [showForm, setShowForm] = useState(false);
  const form = useRef();
  const navigate = useNavigate();

  const handleFormSubmit = (e) => {
    setShowForm(false);
    // e.preventDefault();
  };

  const handleFormClose = (e) => {
    navigate("/")
  }

  const sendEmail = (e) => {
    e.preventDefault();
   
    emailjs
      .sendForm("service_9mh32bh", "template_iucwdt1", form.current, {
        publicKey: "9BO9CFVCt4ge1O4gY",
      })
      .then(
        (result) => {
          console.log("SUCCESS!", result.text);
          console.log("Message Sent");
          e.target.reset();
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  // If package is not hit the accurate ID the o/p will be below code
  const { id } = useParams();
  const details = packageDetails[id];

  if (!details) {
    return <div>Package not found.</div>;
  }

  return (
    <>
      <Navbar />
      <div className="sample-package-details">
        <div className="package-content">
          <h1>{details.title}</h1>

          {details.image.map((img, index) => (
            <img key={index} src={img} alt="package-image"/>
          ))}
          
          <div className="overview">
          <h3>Overview</h3>
          {details.overview.map((view, index) => (
            <p key={index}>{view}</p>
          ))}
          </div>
          

          {/* Iterenery code */}
          <div className="iterenery">
          <h3>Itinerary</h3>
          <ul>
            {details.desc.map((day, index) => (
              <li key={index}>
                <strong>{day.day}:</strong> <br/> {day.details}
              </li>
            ))}
          </ul>
          </div>
          
          {/* inclusions code */}
          <div className="inclusions">
            <h3>Inclusions</h3>
            <ul>
              {details.inclusion.map((activity, index) => (
                <li key={index}>{activity}</li>
              ))}
            </ul>
          </div>

          {/* exclusions code */}
          <div className="exclusions">
            <h3>Exclusions</h3>
            <ul>
              {details.exclusion.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
          
          {/* Policies */}
          <div className="policy">
            <h3>Policy</h3>
            <ul>
              {details.policy.map((policy, index) => (
                <li key={index}>{policy}</li>
              ))}
            </ul>
          </div>
        </div>
        
          <GetCallBack />
        
        {/* Enguiry Now functionality code */}
        {/* <div className="enquiry-section">
        <h3>Allow Us to Call You Back!</h3>
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" placeholder="Name" name="user_name" required />
          <input type="text" placeholder="City" name="user_city" required />
          <input type="email" placeholder="Email address" name="user_email" required />
          <input type="number" placeholder="Phone" name="user_number" maxLength={10} required />
          
            <input
              type="text"
              list="options"
              id="destination"
              name="user_destination"
              placeholder="Choose Destinations"
              required
            />
            <datalist id="options">
              <option value="Pinnawala Elephant Orphanage" />
              <option value="Galle Port" />
              <option value="Iconic lotus Tower" />
              <option value="Sigiriya" />
              <option value="Nine Arch Bridge" />
              <option value="Others Destinations" />
            </datalist>
          <input type="number" placeholder="Travel Count" name="user_people" required />
          <button type="submit" onClick={handleFormSubmit}>Send Enquiry</button>
        </form>
      </div> */}
      
      </div>
    </>
  );
};

export default PackageDetails;
