import Navbar from "../components/Navbar";
import Hero from "../components/hero";
import travelImg from "../assets/images/banners/3.png";
import Footer from "../components/Footer";
import Packages from "../components/Packages";

function PackagesPage() {
    return (
        <>
            <Navbar />
            <Hero
                cName="hero-mid"
                heroImg={travelImg}
                title=""
                btnClass="hide"
            />
            <Packages />
            <Footer />
        </>
    );
}

export default PackagesPage;
