import React from 'react';
import "./AboutStyles.css";
import sabeer from "../assets/images/about-us/Sabeer.webp";
import dilan from "../assets/images/about-us/Dhilan.webp";
import nihila from "../assets/images/about-us/Nihila.webp";
import aboutUsImage from "../assets/images/about-us/gallery-img01.jpg";

const teamMembers = [
  {
    name: "Dilan De Silva",
    role: "Managing Director",
    description: "Mr. Dilan De Silva oversees the smooth running of the business and is responsible for developing strategies to hit or exceed sales targets, and for staff, financial and daily operational management.",
    imgSrc: dilan,
  },
  {
    name: "Sabeer",
    role: "Founder & CEO",
    description: "Mr. Sabeer is a dynamic and visionary entrepreneur hailing from Lakshadweep, distinguished for spearheading and steering a diverse portfolio of successful ventures.",
    imgSrc: sabeer
  },
  {
    name: "Nihila Aboo",
    role: "Director",
    description: "Ms. Nihila Aboo is responsible for the organisation's daily operations, including reservations, ticketing, travel coordination, and customer service.",
    imgSrc: nihila
  }
];

const AboutUs = () => {
  return (
    <>

    <div className="about-us-card">
        <div className="about-us-image">
            <img src={aboutUsImage} alt="About Us" />
        </div>
        <div className="about-us-content">
            <h2>ABOUT US</h2>

            <p>
            At GoL Travels, we believe travel isn’t just about visiting places; it's also about creating stories your clients will cherish forever. As a trusted B2B partner, we bring the vibrant essence of Sri Lanka closer to your business, offering tailored travel experiences that align perfectly with your clients’ expectations.
            
              From beachfront resorts to charming boutique stays, we provide accommodations that leave a lasting impression. Our deep-rooted connections ensure you get access to the best in class, whether it’s serene coastal retreats or hidden gems in Sri Lanka’s lush hill country.
           
            The experiences we curate are designed to inspire. Whether it is a heart-pounding wildlife safari or an exploration of ancient cultural wonders at a UNESCO cultural heritage site, every activity is crafted to offer a unique glimpse into Sri Lanka’s diverse beauty.
            </p>
            <p>
            Transportation is more than getting from one place to another; it’s about travelling in comfort and style. With our fleet of modern vehicles and professional drivers, we ensure every journey is smooth, safe, and stress-free.
            
            What sets us apart is our commitment to excellence. We’re not just providing services; we're also adding value to your business by delivering seamless operations, personalised support, and memorable journeys for your clients.
            
              With GoL Travels as your partner, you’re not just offering a trip to Sri Lanka; you’re delivering an experience they’ll want to relive again and again.
            </p>
            <p>Let’s redefine travel together, one unforgettable journey at a time.</p>
        </div>
        </div>
    
   
    <div className="about-us-container">
      {teamMembers.map((member, index) => (
        <div className="about-card" key={index}>
          <div className="image-container">
            <img src={member.imgSrc} alt={member.name} />
          </div>
          <h2>{member.name}</h2>
          <h3>{member.role}</h3>
          <p>{member.description}</p>
        </div>
      ))}
    </div>
    </>
  );
};

export default AboutUs;


// Below Code is previous website content 
            //  <p>
            // Welcome to the world of <strong>GoL Travels Lanka Pvt Ltd</strong>, where we weave dreams into realities and transform your travel aspirations into unforgettable experiences.
            // As the Director of our esteemed company, I am thrilled to extend a warm invitation to explore the breathtaking wonders of Sri Lanka with us. Nestled within our picturesque island,
            // you'll discover a tapestry of landscapes ranging from pristine beaches to misty mountains, ancient ruins to vibrant cities, and verdant tea plantations to untamed wilderness.
            // </p>
            // <p>
            // At GoL Travels, we pride ourselves on our unwavering commitment to excellence, ensuring that every aspect of your journey is meticulously planned and executed with precision.
            // Our team of seasoned professionals boasts an intimate knowledge of the region, allowing us to craft bespoke itineraries tailored to your interests, preferences, and desires.
            // </p>
            // <p>
            // Whether you're seeking a blissful honeymoon retreat, a thrilling adventure holiday, or a culturally enriching exploration, we have the expertise and resources to make it a reality.
            // But our dedication doesn't end there. We believe in fostering meaningful connections and creating authentic experiences that leave a lasting impact. From immersive cultural encounters
            // and responsible eco-tourism initiatives to luxurious accommodations and seamless logistics, we go above and beyond to ensure that your time with us is nothing short of extraordinary.
            // </p>
            // <p>
            // So, whether you're a solo traveler, a couple seeking romance, a family yearning for adventure, or a group of friends in search of camaraderie, let GoL Travels Lanka Pvt Ltd
            // be your trusted companion on this journey of discovery. Embrace the magic of Sri Lanka with us, and let the memories we create together be the souvenirs of a lifetime.
            // </p> 