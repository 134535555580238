

import React from 'react';
import './Footer.css';
// import logo from '../assets/logo.png'; 
// import logo from '../../assets/images/logo1.png';
import logo from "../assets/logo1.png"

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-container">
        {/* Logo and About Section */}
        <div className="footer-section about">
          <img src={logo} alt="GOL Logo" className="footer-logo" />
          <p>
            GOL is entirely owned and managed by Gol Travels Lanka Pvt Ltd. GOL is striving hard for
            ultimate success and innovation. We are a registered company as a tour & travel company
            that aims to set clear goals, fix the priorities and organize the resources effectively.
          </p>
        </div>

        {/* Contact Information Section */}
        <div className="footer-section contact-info">
          <h3>Contact Us</h3>
          <p>Feel free to contact and reach us !!</p>
          <p>📞 +94 0777904844</p>
          <p>✉️ info@goltravelsrilanka.com</p>
          <p>📍 242/2 Nawala Road Nawala, Colombo, Sri Lanka</p>

          <h3>Branches</h3>
          <p className='branches'>India, Maldives, Thailand</p>
        </div>

        {/* Newsletter Subscription Section */}
        <div className="footer-section newsletter">
          <h3>Subscribe our newsletter for more update & news !!</h3>
          <form className="newsletter-form">
            <input type="email" placeholder="Enter Your Email" className="newsletter-input" />
            <button type="submit" className="newsletter-button">SUBSCRIBE</button>
          </form>
        </div>
      </div>

      {/* Bottom Copyright Section */}
      <div className="footer-bottom">
        <p>Copyright, Gol Travels Lanka Pvt Ltd. All rights reserved.</p>
        <div className="footer-links">
          <a href="/">Privacy Policy</a> | <a href="/">Term & Condition</a> | <a href="/">FAQ</a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;