import { Col, Container, Row } from "react-bootstrap";
import Destination from "../components/Destination";
import Footer from "../components/Footer";
import MasonryImagesGallery from "../components/Image-gallery/MasonryImagesGallery";
// import MasonryImagesGallery from "../components/Gallery/MasonryImagesGallery";
import Navbar from "../components/Navbar"
import Hero from "../components/hero";
import Trip from "../components/trip";
import Packages from "../components/Packages";
import mainBanner from "../assets/images/banners/main-banner11.png";
// import AutoLaunchModal from "../components/EnquiryForm/PopupModel";
import Testimonials from "../components/Testimonial/Testimonials";
import Carousel from "../components/Testimonial/Testimonials";
import GalleryImage from "../components/Gallery/GalleryImage";
import HomePage from "../components/SamplePackages/homePage";
import GetCallBack from "../components/GetCallBack";
// import RequestCallback from "../pages/RequestCallback";
// import { useRouteError } from "react-router-dom";

function Home () {
  // const router = useRouteError();

  // const handleRequestCallback = () => {
  //   router.push("/RequestCallback"); // Redirects to the RequestCallback component
  // };
    return (
        <>
        {/* <AutoLaunchModal /> */}
        <Navbar/>
         <Hero
         cName="hero"
         heroImg={mainBanner}
        //  title="Experience Paradise - explore Sri Lanka's rich heritage"
         text="Sri Lanka : Where every moment is a journey of discovery"
         buttonText="Get Agent help"
        //  url="/GetCallBack"
         btnClass="show"
        //  onClick={GetCallBack}
         />

         <Packages />
         <Destination/>
         <Trip/>
         <Carousel  />
         {/* <HomePage /> */}
           
         {/* <MasonryImagesGallery /> */}
         {/* <Container>
          <Row>
            <Col lg="12">
             
              <h2 className="gallery__title">
                Visit our customers tour gallery
              </h2>
            </Col>
            <Col lg="12">
              <MasonryImagesGallery />
             
            </Col>
          </Row>
        </Container> */}


         <MasonryImagesGallery />
         <Footer/>
        </>
    )
}

export default Home;