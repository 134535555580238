import React from 'react';
import './WhatWeDo.css';

const WhatWeDo = () => {
  return (
    <div className='what-we-do'>
        <p>Welcome to GoL Travels Lanka Pvt Ltd., your one-stop solution for all your travel needs, both inbound and outbound. As a premier Destination Management Company (DMC) based in Colombo, we offer a diverse range of travel services tailored to our valued guests. 
        <br/><br/>

        At GoL Travels, we understand that every traveler has a unique story and a unique adventure to unfold. That's why we take extra care in providing top-notch quality and world-class experiences across all our travel packages. Whether you're looking for a serene getaway or an exciting adventure, we have something for everyone.
        <br/><br/>
        
        We offer a wide variety of tour packages to suit all types of travelers. Our inbound and outbound packages cater to solo adventurers, families, groups, and corporate clients. Whether you're planning a group tour, a corporate retreat, or a romantic getaway, we have the perfect package for you. For those looking to explore the high seas, our mesmerizing cruise operations and shore excursions in Sri Lanka promise unforgettable experiences.
        <br/><br/>
        
        Our locally situated office in Colombo ensures that we are always at hand to provide authentic and efficient services. Our team of experienced professionals is dedicated to making your travel experience seamless and enjoyable. From the moment you book with us until the end of your journey, we are committed to delivering exceptional service and unforgettable memories.
        <br/><br/>
        
        At GoL Travels, we pride ourselves on our attention to detail and our commitment to excellence. We believe that travel is not just about visiting new places, but about creating lasting memories and enriching experiences. Let us be your trusted partner in exploring the wonders of Sri Lanka and beyond.
        <br/><br/>
        
        Choose the best with us, Choose your dreams to travel that deserve the best.</p>
    </div>
  )
}

export default WhatWeDo;