// import { Component } from "react";
// import "./DestinationStyles.css"



// class DestinationData extends Component {
//     render() {
//         return(
//             <div className={this.props.className}>
//                 <div className="des-text">
//                     <h2>{this.props.heading}</h2>
//                     <p> {this.props.text}</p>
//                     </div>

//                     <div className="image">
//                         <img alt="img" src={this.props.img1}/>
//                         <img alt="img" src={this.props.img2}/>
//                     </div>
//                 </div>
//         )
//     }
// }

// export default DestinationData;


// Belove code Functional Componnent and Above code is Class Component 

import React from "react";
import "./DestinationStyles.css";

const DestinationData = (props) => {
    return (
        <div className="destinations-container">
        <div className={props.className}>
            <div className="des-text">
                <h2>{props.heading}</h2>
                <p>{props.text}</p>
            </div>

            <div className="image">
                <img alt="img" src={props.img1} />
                <img alt="img" src={props.img2} />
            </div>
        </div>
        </div>
    );
};

export default DestinationData;
