import Navbar from "../components/Navbar";
import Hero from "../components/hero";
import travelImg from "../assets/images/banners/travel-map.jpg";
import Footer from "../components/Footer";
import AboutUs from "../components/AboutUs";
// import GetCallBackForm from "../components/CallBack/GetCallBackForm";


function About () {
    return (
        <>
        <Navbar/>
         <Hero
         cName="hero-mid"
         heroImg={travelImg}
         title=""
         btnClass="hide"
          width="100%"
         height="400px"
         />
         <AboutUs/>
         {/* <GetCallBackForm /> */}
         <Footer/>
        </>
        
    )


}

export default About;