import Sigiriya1 from "../assets/images/top-destinations/Sigiriya1.png";
import Sigiriya2 from "../assets/images/top-destinations/Sigiriya2.png";
import Temple1 from "../assets/images/top-destinations/Temple1.png";
import Temple2 from "../assets/images/top-destinations/Temple2.png";
import Galle1 from "../assets/images/top-destinations/Galle1.png";
import Galle2 from "../assets/images/top-destinations/Galle2.png";
import LotusTower1 from "../assets/images/top-destinations/LotusTower3.png";
import LotusTower2 from "../assets/images/top-destinations/LotusTower4.png";

import DestinationData from "./DestinationData"
import "./DestinationStyles.css"

const Destination = () => {
    return (
        <div className="destination">
            <div className="destination-content">
            <h1>Explore Our Top Destinations</h1>
               <p>Welcome to GoL Travels, your trusted Destination Management Company for Sri Lanka, a land that holds unparalleled beauty and rich culture. Often referred to as the "Pearl of the Indian Ocean," Sri Lanka is a treasure house of experiences, from peaceful beaches and misty hill stations to vibrant wildlife and UNESCO World Heritage Sites. At GoL Travels, we do more than simply move logistics to create meaningful journeys for your clients.
                <br/><br/>
                Whether it’s a beach holiday, immersive cultural tours, or thrilling wildlife safaris, we ensure every itinerary is detailed and seamless. Our local expertise allows us to provide exclusive access to unique destinations, sustainable travel solutions, and experiences that showcase Sri Lanka’s heart. Partner with us to offer your clients an exceptional Sri Lankan journey, complete with personalised service, reliable support, and memorable moments at every step.
                <br/><br/>
                Together, we create travel experiences that inspire and leave lasting impressions.
               </p>
            </div>
            {/* <DestinationData
            className="first-des"
            heading = "Temple of the Tooth"
            text = "The Temple of the Tooth (Sri Dalada Maligawa) is a sacred Buddhist temple located in Kandy, Sri Lanka. It houses the revered relic of the tooth of Lord Buddha, making it a site of immense spiritual significance for Buddhists worldwide. Nestled within the historic royal palace complex, the temple features intricate architecture adorned with carvings, frescoes, and traditional Kandyan artistry. The annual Esala Perahera festival, a grand cultural procession, further enhances its prominence. Visitors are welcomed to witness serene rituals and immerse themselves in the tranquil ambiance, making the temple a must-visit destination for cultural and spiritual exploration."
            img1 = {Temple2}
            img2 = {Temple1}
            /> */}

            <DestinationData
            className="first-des-reverse"
            heading = "Sigiriya Rock"
            text = "Sigiriya Rock Fortress, also known as the Lion Rock is a UNESCO World Heritage Site and one of Sri Lankas most iconic landmarks. Rising 200 meters above the surrounding plains, this ancient fortress is both an archaeological and architectural marvel. Built in the 5th century by King Kashyapa, it features remnants of a royal palace, vibrant frescoes, and the famous Mirror Wall. Visitors can climb the rock via a series of staircases that pass through the monumental Lions Gate, offering breathtaking views of the surrounding landscapes. Sigiriya is a testament to Sri Lankas rich history and engineering brilliance."
            img1 = {Sigiriya2}
            img2 = {Sigiriya1}
            />
            {/* <DestinationData
            className="first-des"
            heading = "Galle Fort"
            text = "Galle Fort, a UNESCO World Heritage Site, is a historic gem located on the southern coast of Sri Lanka. Originally built by the Portuguese in the 16th century and later fortified by the Dutch, this iconic site showcases a blend of European and South Asian architectural styles. The fort’s cobblestone streets are lined with colonial-era buildings, boutique shops, cafes, and museums. Visitors can explore landmarks like the Galle Lighthouse, the Dutch Reformed Church, and the fort walls offering panoramic views of the ocean. Galle Fort is a living testament to Sri Lanka's rich colonial past and cultural heritage."
            img1 = {Galle1}
            img2 = {Galle2}
            /> */}

            <DestinationData
            className="first-des"
            heading = "Iconic Lotus Tower"
            text = "The Lotus Tower (Nelum Kuluna) is a striking architectural marvel and the tallest structure in South Asia, located in Colombo, Sri Lanka. Standing at 356 meters, it is designed to resemble a blooming lotus, symbolizing the country’s cultural heritage and prosperity. The tower serves as a multi-functional space, featuring a telecommunications hub, observation decks, banquet halls, and entertainment areas. Visitors can enjoy panoramic views of Colombo and the surrounding coastline from its observation deck. At night, the tower comes alive with vibrant LED lights, making it a dazzling icon of Sri Lanka’s modernization and innovation."
            img1 = {LotusTower1}
            img2 = {LotusTower2}
            />

           <button className="moreDestinations">More Destinations</button>

            {/* <DestinationData
            className="first-des"
            heading = "Nine Arch Bridge, one of Sri Lanka’s iconic colonial railway structures."
            text = "The Nine Arch Bridge, located near Ella in Sri Lanka, is one of the country’s most iconic colonial-era railway structures. Built in 1921 during British colonial rule, this stunning bridge is renowned for its nine elegant arches and scenic setting amidst lush, misty highlands. Also known as the “Bridge in the Sky,” the Nine Arch Bridge stretches 91 meters in length and stands 24 meters high, constructed entirely out of stone and cement without a single piece of steel, showcasing remarkable engineering from a bygone era. The bridge connects two cliffs and provides a striking pathway for trains winding through Sri Lanka’s picturesque tea country. A favorite among photographers and tourists, the bridge offers breathtaking views and a unique spot to witness passing trains framed by surrounding jungles and mountains. It’s a must-visit for those exploring Sri Lanka’s hill country, embodying both historical significance and natural beauty."
            img1 = {Mountain3}
            img2 = {Mountain4}
            />
            
            <DestinationData
            className="first-des-reverse"
            heading = "Ambuluwawa Tower, a multi-religious site with panoramic views."
            text = "Ambuluwawa Tower, located near Gampola in Sri Lanka’s central province, is a unique multi-religious site celebrated for its spiritual symbolism and breathtaking views. Perched atop the Ambuluwawa mountain, the tower rises over 1,000 meters above sea level, offering panoramic vistas of the surrounding valleys, rivers, and lush forests. The design of the Ambuluwawa complex reflects Sri Lanka’s diversity, incorporating Buddhist, Hindu, Christian, and Islamic architectural elements. At the peak, the slender white spiraling tower resembles a winding staircase leading skyward, and its summit provides a 360-degree view of Sri Lanka’s central hills. Beyond its spiritual significance, the site is known for its striking architecture and adventurous climb, drawing both devotees and tourists. Whether visitors come for reflection, photography, or simply to experience the stunning landscapes, Ambuluwawa Tower stands as a symbol of harmony and coexistence amidst nature’s grandeur."
            img1 = {Mountain3}
            img2 = {Mountain4}
            />
            
            <DestinationData
            className="first-des"
            heading = "Pinnawala Elephant Orphanage."
            text = "Pinnawala Elephant Orphanage, located near Kegalle in Sri Lanka, is a renowned sanctuary dedicated to the care and conservation of orphaned, injured, and abandoned elephants. Established in 1975 by the Sri Lankan Department of Wildlife Conservation, it provides a safe haven for elephants who cannot return to the wild. Currently, it houses one of the largest captive elephant populations in the world. The orphanage is famous for its twice-daily river bathing sessions, where visitors can watch these gentle giants enjoy a leisurely bath in the Ma Oya River. Additionally, Pinnawala allows visitors to observe feeding times and learn about elephant conservation efforts, offering an educational and unforgettable experience. The orphanage also supports breeding initiatives, with several calves born here, contributing to the preservation of the species. Pinnawala has become one of Sri Lanka’s most popular attractions, combining conservation with tourism to support the well-being of elephants and raise awareness about their protection."
            img1 = {Mountain3}
            img2 = {Mountain4}
            />

            <DestinationData
            className="first-des-reverse"
            heading = "Udawalawe National Park"
            text = "Udawalawe National Park, located in southern Sri Lanka, is a premier wildlife sanctuary famous for its thriving population of elephants and diverse ecosystems. Established in 1972, the park spans over 30,800 hectares of grasslands, marshes, and dense forests, all set against the scenic backdrop of the Udawalawe Reservoir and distant mountain ranges. The park is home to over 500 wild elephants, offering one of the best opportunities in Asia to observe these majestic creatures in their natural habitat. Visitors can often witness herds of elephants, including calves, gathering around water sources—a rare sight that has made Udawalawe a top destination for wildlife enthusiasts. In addition to elephants, Udawalawe hosts a variety of other wildlife, including leopards, water buffalo, spotted deer, sambar deer, and over 200 bird species, making it a haven for birdwatchers. With jeep safaris available throughout the day, Udawalawe National Park provides an unforgettable experience, combining the thrill of wildlife observation with Sri Lanka’s stunning natural beauty."
            img1 = {Mountain3}
            img2 = {Mountain4}
            />

            <DestinationData
            className="first-des"
            heading = "A Traditional Kandyan Dance Show"
            text = "A Traditional Kandyan Dance Show is a captivating cultural experience that takes place in the evenings, especially in the city of Kandy, Sri Lanka. This traditional art form, dating back centuries, is rooted in Sri Lanka’s ancient rituals and royal ceremonies and is known for its vibrant costumes, rhythmic drumming, and expressive movements. The performance typically begins with Ves dancers, adorned in ornate silver headpieces and elaborate costumes, performing ceremonial dances that symbolize blessings. The show includes various traditional dances, each with unique choreography inspired by elements of nature, mythology, and folklore. The fire-dancing segment, where performers handle fire torches and even walk on hot coals, is particularly mesmerizing and demonstrates the dancers' skill and endurance. Accompanied by lively drumming on traditional instruments like the geta bera, the show offers an immersive glimpse into the Kandyan cultural heritage, leaving audiences with a deep appreciation of Sri Lanka’s rich artistic traditions."
            img1 = {Mountain3}
            img2 = {Mountain4}
            />
            
            <DestinationData
            className="first-des-reverse"
            heading = "The Bhakta Hanuman Temple"
            text = "The Bhakta Hanuman Temple, situated in the scenic town of Ramboda in Sri Lanka's central highlands, is a revered Hindu temple dedicated to Lord Hanuman. Set amidst lush tea plantations and misty mountains, the temple is located along the main route between Kandy and Nuwara Eliya, making it an ideal spiritual and cultural stop for travelers. Built by the Chinmaya Mission in honor of Lord Hanuman, a central figure in the ancient Hindu epic, the Ramayana, the temple is believed to be near the spot where Hanuman first set foot in Sri Lanka while searching for Sita. The temple houses a large statue of Lord Hanuman, and the architecture reflects traditional South Indian temple designs, offering a serene place of worship. The temple also features a small museum and interpretive center, where visitors can learn about the Ramayana and its connection to Sri Lanka. With panoramic views of the surrounding hills, the Bhakta Hanuman Temple is both a place of devotion and a site of historical and cultural significance."
            img1 = {Mountain3}
            img2 = {Mountain4}
            /> */}
            
        </div>

    )
}

export default Destination;