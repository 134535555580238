// import React, { useState } from 'react';
// import './ViewDetails.css';

// const itineraryData = [
//   { day: "Day 1", title: "Arrival at Bangaram Island" },
//   { day: "Day 2", title: "Water Sports Adventures at Bangaram Island" },
//   { day: "Day 3", title: "Island Exploration" },
//   { day: "Day 4", title: "Departure from Bangaram Island" },
// ];

// const inclusions = [
//   "Entry Permit",
//   "Pick-up and drop at Agatti airport",
//   "Boat transport to and from Bangaram",
//   "All Meals (Breakfast, Lunch, Dinner)",
//   "Accommodation in AC room",
//   "Water sports activities",
// ];

// const exclusions = [
//   "Entry Permit",
//   "Pick-up and drop at Agatti airport",
//   "Boat transport to and from Bangaram",
//   "All Meals (Breakfast, Lunch, Dinner)",
//   "Accommodation in AC room",
//   "Water sports activities",
// ];



// const ViewDetails = () => {
//   const [activeDay, setActiveDay] = useState(null);

//   const toggleDay = (index) => {
//     setActiveDay(activeDay === index ? null : index);
//   };

//   return (
//     <div className="itinerary-container">
//       <div className='itirnery-details'>
//       <h1>BANGARAM ISLAND</h1>
//       <div className="overview-section">
//         <div className="info-box">
//           <div className="info-item">📅 Duration: 3 Nights / 4 Days</div>
//           <div className="info-item">📍 Pickup & Drop: Agatti Airport</div>
//         </div>
//         <h2>Overview</h2>
//         <p>
//           Bangaram Island, located in the Lakshadweep archipelago of India, is a tropical paradise known for its pristine beaches, crystal-clear waters, and vibrant coral reefs. This idyllic island offers a serene escape with opportunities for snorkeling, scuba diving, and water sports. With its lush greenery and tranquil ambiance, Bangaram is perfect for relaxation and exploration.
//         </p>
//       </div>

//       <h2>Itinerary</h2>
//       <div className="itinerary-list">
//         {itineraryData.map((item, index) => (
//           <div key={index} className="itinerary-item" onClick={() => toggleDay(index)}>
//             <div className="itinerary-header">
//               <h3>{item.day} - {item.title}</h3>
//               <span>{activeDay === index ? '▼' : '▶'}</span>
//             </div>
//             {activeDay === index && (
//               <p className="itinerary-content">Details for {item.title}.</p>
//             )}
//           </div>
//         ))}
//       </div>

//       <h2>Inclusions</h2>
//       <ul className="inclusions-list">
//         {inclusions.map((item, index) => (
//           <li key={index}>✔ {item}</li>
//         ))}
//       </ul>

//       <h2>Exclusions</h2>
//       <ul className="inclusions-list">
//         {exclusions.map((item, index) => (
//           <li key={index}>✔ {item}</li>
//         ))}
//       </ul>
//       </div>

//       <div className="enquiry-section">
//         <h3>Allow Us to Call You Back!</h3>
//         <form>
//           <input type="text" placeholder="Name" required />
//           <input type="email" placeholder="Email address" required />
//           <input type="tel" placeholder="Phone" required />
//           <input type="date" placeholder="Travel Date" required />
//           <input type="number" placeholder="Travel Count" required />
//           <button type="submit">Send Enquiry</button>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default ViewDetails;



import React, { useState } from 'react';
import './ViewDetails.css';

const itineryData = [
  {
    id: "Srilanka_one",
    title: "Sri Lanka Ancient Wonders",
    city: "Srilanka",
    distance: 300,
    price: 109021,
    address: "Srilanka",
    packages: "6 Nights/7 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: [
      { day: "Day 1", details: "Upon your arrival at Bandaranaike International Airport..." },
      { day: "Day 2", details: "After indulging in a hearty breakfast, embark on a scenic journey..." },
      { day: "Day 3", details: "Start your day with a delicious breakfast before diving into..." },
      { day: "Day 4", details: "After a rejuvenating breakfast, depart for the coastal paradise..." },
      { day: "Day 5", details: "Explore the enchanting attractions of Mirissa after breakfast..." },
      { day: "Day 6", details: "Embark on an enchanting journey to Bentota after breakfast..." },
      { day: "Day 7", details: "After a leisurely breakfast, bid farewell to the picturesque landscapes..." },
    ],
    inclusion: [
      "Private air-conditioned tourist vehicle with an English-speaking chauffeur guide for tours and transfers.",
      "Accommodation: Double/Single/Triple rooms on Half Board Basis.",
      "Free visits to Galle Fort, Mask factory, and other attractions.",
      "Entrance fees to Madu River boat safari, Turtle Hatchery, and more.",
    ],
    exclusion: [
      "Personal Expenses such as telephone calls, laundry, drinks, etc.",
      "Lunch during the stay in Sri Lanka.",
      "Tips and Porterage, Camera Permits for filming.",
    ],
    policy: [
      "Full payment (100%) is required for bookings made in the same month.",
      "Bookings within 2-3 months require a 25% payment of the total amount.",
      "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      "Full settlement must occur 14 days before the arrival date.",
    ],
    avgRating: 4.5,
  },
 
  // {
  //   id: "Srilanka_two",
  //   title: "Sri Lanka Ancient Wonders",
  //   city: "Srilanka",
  //   distance: 300,
  //   price: 109021,
  //   address: "Srilanka",
  //   packages: "6 Nights/7 days",
  //   maxGroupSize: 2,
  //   person: "/2 adults",
  //   desc: [
  //     { day: "Day 1", details: "Upon your arrival at Bandaranaike International Airport..." },
  //     { day: "Day 2", details: "After indulging in a hearty breakfast, embark on a scenic journey..." },
  //     { day: "Day 3", details: "Start your day with a delicious breakfast before diving into..." },
  //     { day: "Day 4", details: "After a rejuvenating breakfast, depart for the coastal paradise..." },
  //     { day: "Day 5", details: "Explore the enchanting attractions of Mirissa after breakfast..." },
  //     { day: "Day 6", details: "Embark on an enchanting journey to Bentota after breakfast..." },
  //     { day: "Day 7", details: "After a leisurely breakfast, bid farewell to the picturesque landscapes..." },
  //   ],
  //   inclusion: [
  //     "Private air-conditioned tourist vehicle with an English-speaking chauffeur guide for tours and transfers.",
  //     "Accommodation: Double/Single/Triple rooms on Half Board Basis.",
  //     "Free visits to Galle Fort, Mask factory, and other attractions.",
  //     "Entrance fees to Madu River boat safari, Turtle Hatchery, and more.",
  //   ],
  //   exclusion: [
  //     "Personal Expenses such as telephone calls, laundry, drinks, etc.",
  //     "Lunch during the stay in Sri Lanka.",
  //     "Tips and Porterage, Camera Permits for filming.",
  //   ],
  //   policy: [
  //     "Full payment (100%) is required for bookings made in the same month.",
  //     "Bookings within 2-3 months require a 25% payment of the total amount.",
  //     "Bookings exceeding 4 months necessitate a 10% payment upfront.",
  //     "Full settlement must occur 14 days before the arrival date.",
  //   ],
  //   avgRating: 4.5,
  // },
 
  // {
  //   id: "Srilanka_three",
  //   title: "Sri Lanka Ancient Wonders",
  //   city: "Srilanka",
  //   distance: 300,
  //   price: 109021,
  //   address: "Srilanka",
  //   packages: "6 Nights/7 days",
  //   maxGroupSize: 2,
  //   person: "/2 adults",
  //   desc: [
  //     { day: "Day 1", details: "Upon your arrival at Bandaranaike International Airport..." },
  //     { day: "Day 2", details: "After indulging in a hearty breakfast, embark on a scenic journey..." },
  //     { day: "Day 3", details: "Start your day with a delicious breakfast before diving into..." },
  //     { day: "Day 4", details: "After a rejuvenating breakfast, depart for the coastal paradise..." },
  //     { day: "Day 5", details: "Explore the enchanting attractions of Mirissa after breakfast..." },
  //     { day: "Day 6", details: "Embark on an enchanting journey to Bentota after breakfast..." },
  //     { day: "Day 7", details: "After a leisurely breakfast, bid farewell to the picturesque landscapes..." },
  //   ],
  //   inclusion: [
  //     "Private air-conditioned tourist vehicle with an English-speaking chauffeur guide for tours and transfers.",
  //     "Accommodation: Double/Single/Triple rooms on Half Board Basis.",
  //     "Free visits to Galle Fort, Mask factory, and other attractions.",
  //     "Entrance fees to Madu River boat safari, Turtle Hatchery, and more.",
  //   ],
  //   exclusion: [
  //     "Personal Expenses such as telephone calls, laundry, drinks, etc.",
  //     "Lunch during the stay in Sri Lanka.",
  //     "Tips and Porterage, Camera Permits for filming.",
  //   ],
  //   policy: [
  //     "Full payment (100%) is required for bookings made in the same month.",
  //     "Bookings within 2-3 months require a 25% payment of the total amount.",
  //     "Bookings exceeding 4 months necessitate a 10% payment upfront.",
  //     "Full settlement must occur 14 days before the arrival date.",
  //   ],
  //   avgRating: 4.5,
  // },
  // Add more itinerary objects as required
];

const ViewDetails = () => {
  const [activeDay, setActiveDay] = useState(null);

  const toggleDay = (index) => {
    setActiveDay(activeDay === index ? null : index);
  };

  return (
    <div className="itinerary-container">
      {itineryData.map((itinerary) => (
        <div key={itinerary.id} className="itirnery-details">
          <h1>{itinerary.title}</h1>
          <div className="overview-section">
            <div className="info-box">
              <div className="info-item">📅 Duration: {itinerary.packages}</div>
              <div className="info-item">📍 Location: {itinerary.city}</div>
            </div>
            <h2>Overview</h2>
            <p>{itinerary.desc[0].details}</p>
          </div>

          <h2>Itinerary</h2>
          <div className="itinerary-list">
            {itinerary.desc.map((item, index) => (
              <div
                key={index}
                className="itinerary-item"
                onClick={() => toggleDay(index)}
              >
                <div className="itinerary-header">
                  <h3>{item.day}</h3>
                  <span>{activeDay === index ? "▼" : "▶"}</span>
                </div>
                {activeDay === index && (
                  <p className="itinerary-content">{item.details}</p>
                )}
              </div>
            ))}
          </div>

          <h2>Inclusions</h2>
          <ul className="inclusions-list">
            {itinerary.inclusion.map((item, index) => (
              <li key={index}>✔ {item}</li>
            ))}
          </ul>

          <h2>Exclusions</h2>
          <ul className="exclusions-list">
            {itinerary.exclusion.map((item, index) => (
              <li key={index}>✔ {item}</li>
            ))}
          </ul>

          <h2>Policies</h2>
          <ul className="policy-list">
            {itinerary.policy.map((item, index) => (
              <li key={index}>✔ {item}</li>
            ))}
          </ul>
        </div>
      ))}

      <div className="enquiry-section">
        <h3>Allow Us to Call You Back!</h3>
        <form>
          <input type="text" placeholder="Name" required />
          <input type="email" placeholder="Email address" required />
          <input type="tel" placeholder="Phone" required />
          <input type="date" placeholder="Travel Date" required />
          <input type="number" placeholder="Travel Count" required />
          <button type="submit">Send Enquiry</button>
        </form>
      </div>
    </div>
  );
};

export default ViewDetails;