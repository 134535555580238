// import "./ContactFormStyles.css"
import GetCallBack from "./GetCallBack";

function ContactForm () {
    return (
        <div className="form-container">
            <GetCallBack />
        </div>
    ) 
}

export default ContactForm;

        
        // Instead of below code i imported GetCallBack component

        //   <h1>Send a message to us!</h1>
        //     <form>
        //         <input placeholder="Name"/>
        //         <input placeholder="Email"/>
        //         <input placeholder="Subject"/>
        //         <textarea placeholder="Message" rows="4"></textarea>
        //         <button>Send Message</button>
        //     </form>