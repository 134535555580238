import React from "react";
import { Link } from "react-router-dom";

const packages = [
  { id: 1, name: "Hills & Sands", description: "A scenic journey through hills and beaches." },
  { id: 2, name: "Historical & Chill", description: "Explore cultural heritage with a relaxing vibe." },
  { id: 3, name: "Chill & Wild", description: "A mix of relaxation and wildlife adventure." },
];

const HomePage = () => {
  return (
    <div style={{ padding: "20px" }}>
      <h1>Tour Packages</h1>
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: "20px" }}>
        {packages.map((pkg) => (
          <div key={pkg.id} style={{ border: "1px solid #ddd", borderRadius: "5px", padding: "20px" }}>
            <h2>{pkg.name}</h2>
            <p>{pkg.description}</p>
            <Link to={`/package/${pkg.id}`} style={{ textDecoration: "none", color: "white" }}>
              <button style={{ background: "purple", color: "white", padding: "10px", border: "none", borderRadius: "5px" }}>
                View Details
              </button>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomePage;