import "./heroStyles.css"
function Hero (props) {
    return (
        <>
        <div className={props.cName}>
            <img alt="hero" src={props.heroImg}></img>
            <div className="hero-text">
                <h1>{props.title}</h1>
                <p>{props.text}</p>
                <a href={props.url} className={props.btnClass} >{props.buttonText}</a>
            </div>
       
        </div>
        </>
    )


}

export default Hero;


// For request call back

// import React from "react";

// const Hero = ({ cName, heroImg, buttonText, btnClass, onClick }) => {
//   return (
//     <div className={cName} style={{ backgroundImage: `url(${heroImg})` }}>
//       {/* Hero Content */}
//       <div style={styles.heroContent}>
//         <button className={btnClass} onClick={onClick} style={styles.button}>
//           {buttonText}
//         </button>
//       </div>
//     </div>
//   );
// };

// export default Hero;

// // Inline styles (optional, you can replace with CSS)
// const styles = {
//   heroContent: {
//     textAlign: "center",
//     padding: "50px",
//     color: "#fff",
//   },
//   button: {
//     backgroundColor: "#4a90e2",
//     color: "#fff",
//     border: "none",
//     padding: "10px 20px",
//     borderRadius: "5px",
//     cursor: "pointer",
//     fontSize: "16px",
//   },
// };
